// bigelowandholmes.typepad.com/bigelow-holmes/2015/07/on-font-weight.html
$fonts: (
    'Bold': 700,
    'Thin': 100,
    'Ultralight': 200,
    'Light': 300,
    'Book': 350,
    'Regular': 400,
    'Medium': 500,
    'Semibold': 600,
    'SuperBold': 650,
);

@each $font, $weight in $fonts {
    @font-face {
        font-family: 'Piech Sans';
        font-weight: $weight;
        font-style: normal;
        src: local('Piech Sans'),
            url('./../assets/fonts/PiechSans-#{$font}.woff2') format('woff2');
    }
}

* {
    -webkit-font-smoothing: antialiased;
}
