.map {
    height: $screen-height;
}

.mapboxgl-ctrl-bottom-left {
    right: $size-s;
    top: $size-s;
    bottom: auto;
    left: auto;
}

a.mapboxgl-ctrl-logo {
    pointer-events: none;    
    cursor: inherit;
}
