.icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 100%;
        height: 100%;
    }

    &--color {
        &-gray {
            svg path {
                fill: $color-gray2;
            }
        }

        &-white {
            svg path {
                fill: $color-white;
            }
        }

        &-blue {
            svg path {
                fill: $color-blue1;
            }
        }

        &-black {
            svg path {
                fill: $color-black;
            }
        }
    }

    &--size {
        &-xs {
            width: $size-xs;
            height: $size-xs;
        }

        &-s {
            width: $size-s;
            height: $size-s;
        }

        &-m {
            width: $size-m;
            height: $size-m;
        }

        &-l {
            width: $size-l;
            height: $size-l;
        }

        &-xl {
            width: $size-xl;
            height: $size-xl;
        }

        &-xxl {
            width: $size-xxl;
            height: $size-xxl;
        }

        &-xxxl {
            width: $size-xxxl;
            height: $size-xxxl;
        }
    }
}
