@import './components';

.app {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: relative;

    &--offline {
        .app__widget {
            pointer-events: none;
        }
    }
}
