@import 'reset';
@import 'colors';
@import 'sizes';
@import 'fonts';
@import 'typography';
@import 'animation';
@import 'styles';

body {
    font-family: sans-serif;
    background-color: $color-black;
    color: $color-white;
}

$widget-width: 464px;
$screen-height: 480px;
