.button {
    display: inline-flex;
    text-align: center;
    color: $color-white;
    align-items: center;
    justify-content: center;
    // cursor: pointer;
    background-color: transparent;
    user-select: none;

    &__icon + &__label {
        padding-left: $size-s;
    }

    &--size {
        &-small {
            padding: $size-xxs;
        }

        &-medium {
            padding: $size-xs;
        }

        &-large {
            padding: $size-s;
        }
    }

    &--dotted {
        @include dot-pattern();
    }
}
