.message {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;

    &__title,
    &__subtitle {
        text-align: center;
    }

    &__title {
        padding: $size-l 0;
    }
}