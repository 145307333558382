.dev-test {
    width: 100%;
    height: 100vh;
    background-color: #222;

    &__main {
        padding: 40px;
        display: flex;
        justify-content: space-between;
    }

    &__navigation,
    &__widget {
        top: 25px;
        height: $screen-height;
        border: 0;
        outline: 0;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
        border-radius: 20px;
    }

    &__navigation {
        flex: 1;
        margin-right: 40px;
    }

    &__widget {
        flex: 0 0 $widget-width;
    }

    &__actions {
        display: flex;
        padding-left: 40px;
    }

    &__action {
        @include typography(body-xsmall);
        user-select: none;
        margin-right: 30px;
        border: 2px solid #444;
        background: none;
        border-radius: 5px;
        color: #aaa;
        transition: all 0.3s ease;
        padding: 10px 20px;
        outline: none;

        &:hover {
            color: #aaa;
            border-color: #666;
        }

        &:active {
            background-color: #222;
        }
    }
}
