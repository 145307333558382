$fw-book: 350;
$fw-regular: 400;
$fw-semibold: 500;

$fs-xxsmall: 18px;
$fs-xsmall: 20px;
$fs-small: 24px;
$fs-medium: 28px;
$fs-large: 32px;
$fs-xlarge: 36px;
$fs-xxlarge: 40px;
$fs-xxxlarge: 48px;

$lh-small: 1.1em;
$lh-normal: 1.4em;
$lh-large: 1.6em;

$ls-default: 0; 
$ls-small: -1px;
$ls-large: 4.9px;

$tt-uppercase: uppercase;
$tt-none: none;

$typography-styles: (
    'body-xsmall' $fw-book $fs-xsmall $lh-normal,
    'body-small' $fw-regular $fs-small $lh-small,
    'body-medium' $fw-regular $fs-medium $lh-normal,
    'body-large' $fw-regular $fs-large $lh-normal,
    'label-small' $fw-semibold $fs-xxsmall $lh-large $ls-large $tt-uppercase,
    'label-medium' $fw-regular $fs-xlarge $lh-small,
    'label-large' $fw-regular $fs-xxlarge $lh-small $ls-default,
    'label-xlarge' $fw-semibold $fs-xxxlarge $lh-small $ls-default,
);

@mixin get-font-style(
    $weight,
    $size,
    $line-height,
    $letter-spacing: $ls-default,
    $text-transform: $tt-none
) {
    font-family: 'Piech Sans', Helvetica, Arial, sans-serif;
    font-weight: $weight;
    font-style: normal;
    font-size: $size;
    line-height: $line-height;
    @if $letter-spacing {
        letter-spacing: $letter-spacing;
    }
    @if $text-transform {
        text-transform: $text-transform;
    }
}

@each $name, $weight, $size, $lh, $ls, $tt in $typography-styles {
    %typography-#{$name} {
        @include get-font-style($weight, $size, $lh, $ls, $tt);
    }
}

@mixin typography($style, $color: inherit) {
    @extend %typography-#{$style};
    @if $color != inherit {
        color: $color;
    }
}
