.map-mask {
    position: relative;
    width: 100%;
    height: 100%;

    &__top,
    &__bottom,
    &__left,
    &__right {
        position: absolute;
        pointer-events: none;
    }

    &__top,
    &__bottom {
        left: 0;
        right: 0;
        height: 25%;
        z-index: 1;
    }

    &__left,
    &__right {
        top: 0;
        bottom: 0;
        width: 20%;
        z-index: 1;
    }

    &__content {
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    &__top {
        top: 0;
        background-image: linear-gradient(
            rgb(0, 0, 0) 0%,
            rgba(0, 0, 0, 0) 100%
        );
    }

    &__bottom {
        bottom: 0;
        background-image: linear-gradient(
            rgba(0, 0, 0, 0) 0%,
            rgb(0, 0, 0) 100%
        );
    }

    &__left {
        left: 0;
        background-image: linear-gradient(
            to left,
            rgba(0, 0, 0, 0) 0%,
            rgb(0, 0, 0) 100%
        );
    }

    &__right {
        right: 0;
        background-image: linear-gradient(
            to right,
            rgba(0, 0, 0, 0) 0%,
            rgb(0, 0, 0) 100%
        );
    }
}
