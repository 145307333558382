.map-controls {
    display: flex;
    flex-direction: column;

    > * {
        &:not(:last-child) {
            margin-bottom: $size-m;
        }
    }

    &__button {
        border: 1px solid $color-gray5;
    }

    &__zoom {
        display: flex;
        flex-direction: column;
        border: 1px solid $color-gray5;

        &-button:last-child {
            border-top: 1px solid $color-gray5;
        }
    }
}
