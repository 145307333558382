.search-results {
    display: flex;
    flex: 1;

    &__list {
        flex: 1;
        overflow-y: auto;
    }

    &__item {
        padding: $size-m 0;
        border-bottom: 1px solid $color-gray7;
        // cursor: pointer;

        &:hover,
        &:active {
            background-image: linear-gradient(
                    to right,
                    $color-black 0%,
                    rgba(0, 0, 0, 0) 50%
                ),
                linear-gradient(to left, $color-black 0%, rgba(0, 0, 0, 0) 50%),
                linear-gradient(to top, $color-gray6 0%, transparent 50%);
        }
    }
}
