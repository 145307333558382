.actions {
    display: flex;
    width: 100%;

    &--multiple {
        .actions__action:last-child {
            border-left: $border-dark;
        }
    }

    &__action {
        flex: 1;
        border-top: $border-dark;
        border-bottom: $border-dark;
    }
}
