.keyboard-key {
    @include typography(label-large, $color-white);
    border: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    // cursor: pointer;
    transition: border-color 0.2s ease, background-color 0.3s ease-in-out;
    text-transform: uppercase;

    &:hover,
    &:active {
        &:not(:first-child) {
            border-left: $border-dark;
        }

        &:not(:last-child) {
            border-right: $border-dark;
        }
        background-color: $color-gray7;
    }

    &--pressed {
        background-color: $color-gray7;
        mask-image: linear-gradient(
            to bottom,
            rgb(0, 0, 0) 50%,
            rgba(0, 0, 0, 0) 100%
        );
    }

    &--special {
        &-backspace,
        &-enter {
            border-left: $border-dark;
        }

        &-backspace {
            width: 150px;
        }

        &-enter,
        &-layout-switch {
            font-size: $fs-small;
            letter-spacing: $ls-large;
            width: 210px;
        }

        &-space {
            flex: 1;
            border-left: $border-dark;
            border-right: $border-dark;
        }

        &-arrow-left,
        &-arrow-right {
            width: 174px;
        }

        &-arrow-left {
            border-right: $border-dark;
        }
    }
}
