$border-dark: 1px solid $color-gray6;
$border-light: 1px solid $color-gray4;

@mixin dot-pattern(
    $dot-size: 1px,
    $dot-space: 2px,
    $bg-color: $color-black,
    $dot-color: $color-gray6
) {
    background: linear-gradient(
                90deg,
                $bg-color ($dot-space - $dot-size),
                transparent 1%
            )
            center,
        linear-gradient($bg-color ($dot-space - $dot-size), transparent 1%)
            center,
        $dot-color;
    background-size: $dot-space $dot-space;
}
