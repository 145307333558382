.keyboard {
    &__row {
        justify-content: center;
        display: flex;

        &:not(:last-child) {
            border-bottom: $border-dark;
        }

        &:first-child {
            mask-image: linear-gradient(
                to top,
                rgb(0, 0, 0) 70%,
                rgba(0, 0, 0, 0) 100%
            );
        }

        &:last-child {
            mask-image: linear-gradient(
                to bottom,
                rgb(0, 0, 0) 70%,
                rgba(0, 0, 0, 0) 100%
            );
        }
    }
}
