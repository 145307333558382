.route-summary {
    height: 100%;
    max-width: 100%;
    padding: $size-l 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &__not-found {
        flex: 1;
        max-width: 400px;
        padding-top: $size-xxl;
    }
}
