.menu-button {
    padding: $size-l;

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        border: 2.5px solid $color-gray6;
        border-radius: $border-radius-full;
        margin-bottom: $size-xs;
        transition: background-color 0.3s ease;

        &:hover, &:active {
            background-color: $color-gray6;
        }
    }

    .menu-button__labels {
        display: flex;
        flex-direction: column;
    }


    &__title {
        text-align: center;
    }

    &--horizontal {
        padding: 0;

        .menu-button__icon {
            margin-bottom: 0;
            margin-right: $size-l;
        }
        
        .menu-button__content {
            padding-top: 6px;
            flex-direction: row;
            align-items: center;
        }

        .menu-button__title {
            text-align: left;
        }

        .menu-button__subtitle {
            transform: translateY(-1px);
        }

        &:first-child {
            padding-bottom: 38px;

            .menu-button__title {
                transform: translateY(-8px);
            }
            .menu-button__icon {
                transform: translateY(2px);
            }
        }            

        &:last-child {        
            
            .menu-button__title {
                padding-bottom: 6px;
                transform: translateY(-1px);
            }

            .menu-button__icon {
                transform: translateY(6px);
            }
        }
    }

    &--active {
        
        .menu-button__icon {
            background-color: $color-gray2;
        }
    }
}