$size-none: 0;
$size-xxxs: 2px;
$size-xxs: 4px;
$size-xs: 8px;
$size-s: 12px;
$size-m: 24px;
$size-l: 32px;
$size-xl: 48px;
$size-xxl: 64px;
$size-xxxl: 88px;

$border-radius-xs: 2px;
$border-radius-s: 4px;
$border-radius-m: 8px;
$border-radius-l: 16px;
$border-radius-full: 100%;
