.route-summary-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
    flex: 1;

    &__address {
        max-width: 500px;
        padding: $size-m 0;
    }

    &__counters {
        flex: 1;
    }
}
