.main {
    height: $screen-height;

    &__navigation {
        will-change: transform;
        transition: transform 0.5s $ease-in-out-quint;
    }

    &--panel-open {
        .main__navigation {
            transform: translateX(300px);
        }

        .navigation__controls {
            transform: translateX(-300px);
        }
    }

    &--background-mode {
        .main__navigation {
            display: none;
        }
    }
}
