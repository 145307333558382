::placeholder {
    color: $color-gray4;
}

.input {
    display: flex;
    justify-content: space-between;

    &__control {
        @include typography(body-medium, $color-gray3);
        width: 100%;
        padding: $size-m 0;
        background: transparent;
        caret-color: $color-blue1;
    }
}
