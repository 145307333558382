.guidance {
    display: flex;
    align-items: center;

    &__hints {
        display: flex;
        flex-direction: column;
        padding-left: $size-l;
        max-width: 70%;
    }
}