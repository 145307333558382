.text {
    user-select: none;

    &--ellipsis {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
    }
    
    &--variant {
        &-body-xsmall {
            @include typography(body-xsmall);
        }
        &-body-small {
            @include typography(body-small);
        }
        &-body-medium {
            @include typography(body-medium);
        }
        &-body-large {
            @include typography(body-large);
        }
        &-label-small {
            @include typography(label-small);
        }
        &-label-medium {
            @include typography(label-medium);
        }
        &-label-large {
            @include typography(label-large);
        }
        &-label-xlarge {
            @include typography(label-xlarge);
        }
    }
    
    &--color {
        &-white {
            color: $color-white;
        }

        &-gray {
            color: $color-gray3;
        }

        &-blue {
            color: $color-blue1;
        }

        &-red {
            color: $color-red;
        }
        
        &-gold {
            color: $color-gold;
        }
    }
}

.unit {
    color: $color-gray4;
    font-size: 0.7em;
    line-height: normal;
}