@import './components/SearchResults';

.search {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;

    &__form {
        min-width: 7 * $size-xxl;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: $size-l 0 0 0;
    }

    &__query {
        margin-top: $size-s;
        width: 100%;
        font-size: 1.4em;
        border-bottom: $border-light;
    }

    &__results {
        width: 100%;
        flex: 1;
        overflow-y: auto;
        mask-image: linear-gradient(
            to bottom,
            rgb(0, 0, 0) 90%,
            rgba(0, 0, 0, 0) 100%
        );

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__keyboard {
        display: flex;
        flex: 1;
        align-self: center;
        align-items: center;
        justify-content: center;
        margin-left: $size-l;
    }
}
