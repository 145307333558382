@import './components/MapControls';

.navigation {
    position: relative;
    max-height: 100%;

    &__controls {
        position: absolute;
        bottom: $size-m;
        right: $size-m;
        z-index: 100;
        transition: transform 0.5s $ease-in-out-quint;
        transform: translateX(0);
    }
}
