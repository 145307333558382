.widget-layout {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px 48px 7px 48px;
    background: transparent url('./../../assets/images/bg-widget.png') no-repeat right center;        

    &__content {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__title {
        text-align: center;
        transform: translate(2px, -2px);
    }

    &__actions {
        display: flex;
        transform: translateY(-1px);
    }

    &__action {
        width: 80px;
        height: 80px;
        display: flex;
        padding: 16px;
    }

    &__action:first-child {
        margin-right: 40px;
    }

    .menu-section {
        padding-bottom: 0;
    }

    &--ui-comparison {

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 464px;
            height: 480px;
            background: transparent url('./../../assets/images/widget-design-reference.png') no-repeat right center;        
            z-index: 1000;
            opacity: 0.5;
            pointer-events: none;
        }
    }
}
