$panel-width: 640px;

.panel {
    display: flex;
    position: absolute;
    width: $panel-width;
    height: $screen-height;
    overflow: hidden;
    mask-image: linear-gradient(
        to right,
        rgb(0, 0, 0) 75%,
        rgba(0, 0, 0, 0) 100%
    );
    backdrop-filter: blur(10px);
    z-index: 2000;
    transform: translateX(-$panel-width);
    transition: transform 0.5s $ease-in-out-quint;
    will-change: transform;
    align-items: center;
    justify-content: flex-start;
    padding-left: $size-xxxl;

    &--full-size {
        width: 100%;
        background-image: none;
        mask-image: none;
        background-color: $color-black;
        transform: translateX(-100%);
    }

    &--open {
        transform: none;
    }
}
