.menu-section {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    padding-bottom: $size-s;

    &__items {
        display: flex;
        flex-wrap: wrap;
    }
}
